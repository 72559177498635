(function () {
  "use strict";

  // Ana Menü
  const navigation = new Navigation(document.getElementById("navigation"));

  document.getElementById("btn-show").addEventListener("click", navigation.toggleOffcanvas);

  if (document.querySelector('.hero-slider')) {
    const sliderTitles = document.querySelectorAll('.slider-title')
    sliderTitles[0].classList.add('active-title') // ilk slide'a aktif class ekliyoruz
    // console.log(sliderTitles)

    const heroSlider = new Swiper('.hero-slider .swiper', {
      // pagination: {
      //   el: ".hero-slider .swiper-pagination",
      // },
      loop: true,
      navigation: {
        nextEl: ".hero-slider .slider-button-next",
        prevEl: ".hero-slider .slider-button-prev",
      },
      grabCursor: true,
      effect: "creative",
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
      on: {
        slideChangeTransitionStart: function () {
          // Get the current slide index
          var slideIndex = this.realIndex;
          console.log(slideIndex);

          sliderTitles.forEach((title, titleIndex) => {
            const index = titleIndex;
            if (index == slideIndex) {
              title.classList.add("active-title");
            } else {
              title.classList.remove("active-title");
            }
          });
          // gsap.fromTo(
          //   ".activeBG",
          //   1,
          //   {
          //     autoAlpha: 0,
          //     scale: 1.2,
          //   },
          //   {
          //     autoAlpha: 1,
          //     scale: 1,
          //   }
          // );
        },
      },
    })
  }

  // PSS Slider

  if (document.querySelector('.pss-slider')) {
    const pssSlider = new Swiper('.pss-slider .swiper', {
      loop: true,
      slidesPerView: 1.1,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".patient-satisfaction-section .pss-slider-arrow-next",
        prevEl: ".patient-satisfaction-section .pss-slider-arrow-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 2.2,
        },
      },
    })
  }

  // Fixed Whatsapp Tel
  if (document.querySelector('.fixed-whatsapp-tel')) {
    const fwt = document.querySelector('.fixed-whatsapp-tel')

    window.addEventListener("load", (event) => {
      setTimeout(() => {
        fwt.classList.add('show')
      }, 2000)
    });
  }

  // Anasayfa Youtube Video Slider

  if (document.querySelector('.videolar-slider')) {
    const videoSlider = new Swiper('.videolar-slider .swiper', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: ".videolar-slider .swiper-pagination",
        clickable: true,
      },
      // navigation: {
      //   nextEl: ".patient-satisfaction-section .pss-slider-arrow-next",
      //   prevEl: ".patient-satisfaction-section .pss-slider-arrow-prev",
      // },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    })
  }

  // Hizmetler Slider on Mobil

  if (document.querySelector('.anasayfa-treatments')) {
    var init = false;

    function swiperCard() {
      if (window.innerWidth < 768) {
        if (!init) {
          init = true;
          const swiper = new Swiper(".anasayfa-treatments .treatments-slider", {
            slideClass: 'tedavi-card', // .swiper-slider yerine
            loop: true,
            slidesPerView: 1,
            spaceBetween: 40,
            //centeredSlides: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            autoplay: {
              delay: 2000,
            },
          });
        }
      } else if (init) {
        swiper.destroy();
        init = false;
      }
    }
    swiperCard();
    window.addEventListener("resize", swiperCard);
  }

  // Before After Slider
  if (document.querySelector('.before-after-slider')) {
    const beforeAfterSlider = new Swiper('.before-after-slider .swiper', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".pss-slider-arrow-next",
        prevEl: ".pss-slider-arrow-prev",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 35,
        },
      },
    })
  }

  if (document.querySelector('.video-slider')) {
    const videoSliders = document.querySelectorAll('.video-slider .swiper')

    videoSliders.forEach(function (slider) {
      const carousel = new Swiper(slider, {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".pss-slider-arrow-next",
          prevEl: ".pss-slider-arrow-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      })
    })


  }



})();